import React from 'react';
import {graphql} from 'gatsby';

import {Helmet} from 'react-helmet';

import '../styles.scss';

import {Footer} from '../parts/footer';
import {Wave} from '../parts/wave';
import {Navigation} from '../parts/navigation';

export default function Template({data}) {
  const {markdownRemark} = data; // data.markdownRemark holds your post data
  const {frontmatter, html} = markdownRemark;
  return (
    <main className="page-wrapper">
      <div className="page-section">
        <div className="page-main">
          <Navigation />
          <Helmet>
            <meta charSet="utf-8"/>
            <title>David Kaloczi - {frontmatter.title}</title>
            <meta name="description" content="123sadasd"/>
          </Helmet>
          <div className="blog-post">
            <h1>{frontmatter.title}</h1>
            <p>Posted: {frontmatter.date}</p>
            <p>
              {frontmatter.intro}
            </p>
            <hr/>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{__html: html}}
            />
          </div>
        </div>
      <Wave waveNumber={4} />
      </div>
      <Footer />
    </main>
  );
}

export const pageQuery = graphql`
query($id: String) {
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        title
        intro
        date(formatString: "YYYY MMMM DD")
      }
      id
    }
  }
`;
